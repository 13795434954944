const usersData = [
  { coupon: 'ポカリスエットお試しクーポン', discount: '100', registered: '2012/01/01', role: '大塚製薬', status: 'Active'},
  { coupon: 'コカ・コーラお試しクーポン', discount: '100', registered: '2012/02/01', role: 'コカ・コーラ', status: 'Banned'},
  { coupon: 'カロリーメイトお試しクーポン', discount: '100', registered: '2012/02/01', role: '大塚製薬', status: 'Inactive'},
  { coupon: 'ジンジャーエールお試しクーポン', discount: '100', registered: '2012/03/01', role: 'コカ・コーラ', status: 'Pending'},
  { coupon: 'たけのこの里お試しクーポン', discount: '100', registered: '2012/01/21', role: '明治', status: 'Active'},
  { coupon: 'キノコの森お試しクーポン', discount: '100', registered: '2012/01/01', role: '明治', status: 'Active'},
  { coupon: 'キットカットお試しクーポン', discount: '100', registered: '2012/02/01', role: 'ネスレ日本', status: 'Banned', _classes: 'table-success'},
  { coupon: 'ジョージア缶コーヒーお試しクーポン', discount: '100', registered: '2012/02/01', role: 'コカ・コーラ', status: 'Inactive'},
  { coupon: 'キューピーマヨネーズリニューアル記念クーポン', discount: '100', registered: '2012/03/01', role: 'キューピー', status: 'Pending'},
/*   { coupon: 'Agapetus Tadeáš', discount: '100', registered: '2012/01/21', role: 'Staff', status: 'Active'},
  { coupon: 'Carwyn Fachtna', discount: '100', registered: '2012/01/01', role: 'Member', status: 'Active', _classes: 'table-success'},
  { coupon: 'Nehemiah Tatius', discount: '100', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
  { coupon: 'Ebbe Gemariah', discount: '100', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
  { coupon: 'Eustorgios Amulius', discount: '100', registered: '2012/03/01', role: 'Member', status: 'Pending'},
  { coupon: 'Leopold Gáspár', discount: '100', registered: '2012/01/21', role: 'Staff', status: 'Active'},
  { coupon: 'Pompeius René', discount: '100', registered: '2012/01/01', role: 'Member', status: 'Active'},
  { coupon: 'Paĉjo Jadon', discount: '100', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
  { coupon: 'Micheal Mercurius', discount: '100', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
  { coupon: 'Ganesha Dubhghall', discount: '100', registered: '2012/03/01', role: 'Member', status: 'Pending'},
  { coupon: 'Hiroto Šimun', discount: '100', registered: '2012/01/21', role: 'Staff', status: 'Active'},
  { coupon: 'Vishnu Serghei', discount: '100', registered: '2012/01/01', role: 'Member', status: 'Active'},
  { coupon: 'Zbyněk Phoibos', discount: '100', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
  { coupon: 'Einar Randall', discount: '100', registered: '2012/02/01', role: 'Admin', status: 'Inactive', _classes: 'table-danger'},
  { coupon: 'Félix Troels', discount: '100', registered: '2012/03/21', role: 'Staff', status: 'Active'},
  { coupon: 'Aulus Agmundr', discount: '100', registered: '2012/01/01', role: 'Member', status: 'Pending'} */
]

export default usersData


