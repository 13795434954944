var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12", lg: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", [
                _vm._v(" User id: " + _vm._s(_vm.$route.params.id) + " ")
              ]),
              _c(
                "CCardBody",
                [
                  _c("CDataTable", {
                    attrs: {
                      striped: "",
                      small: "",
                      fixed: "",
                      items: _vm.getUserData(_vm.$route.params.id),
                      fields: _vm.$options.fields
                    }
                  })
                ],
                1
              ),
              _c(
                "CCardFooter",
                [
                  _c(
                    "CButton",
                    { attrs: { color: "primary" }, on: { click: _vm.goBack } },
                    [_vm._v("Back")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }